(function ($) {
  Drupal.behaviors.mapBehavior = {
    attach: function(context, settings){
      $('#map', context).once('google-maps', function(){
        var address = "Oostendsesteenweg 162-164, Belgium";
        setupMapWithAddress(address, 'map');
      });
    }
  };

  function setupMapWithAddress(address, mapId) {
    getLatLong(address);
    var locations = [];
    function getLatLong(address) {
      var geo = new google.maps.Geocoder;
      geo.geocode({
        'address': address,
        region: 'no'
      }, function(results, status) {
        if (status.toLowerCase() == 'ok') {
             // locations[] = results[0].geometry.location;
             locations.push(results[0].geometry.location);
             initializeMap(locations, mapId);
           } else {
            // alert("problem");
          }
        });
    }
  }

    function initializeMap(locations, mapId) {
      var gmarkers = [];
      var map = null;
      var infowindow = null;
      var bound = new google.maps.LatLngBounds();

      var styles = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];

      for (var i = 0; i < locations.length; i++) {
        var latLang = convertLatLong(locations[i]);
        bound.extend(new google.maps.LatLng(latLang.lat, latLang.long));
      }

      isDraggable = false;

      // if(Modernizr.touch) isDraggable = false;

      var mapOptions = {
        center: bound.getCenter(),
        zoom: 13,
        panControl: true,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        overviewMapControl: false,
        scrollwheel: false,
        draggable: false,
        // styles: styles,
      };

      map = new google.maps.Map(document.getElementById(mapId), mapOptions);
      var throttler = setTimeout(centerMap, 200);

      $(document).imagesLoaded(function() {
          setTimeout(centerMap, 500);
      });


      $(window).resize(function(event) {
        if(throttler) clearTimeout(throttler);
        throttler = setTimeout(centerMap, 500);
      });

      function centerMap() {
        map.setCenter(bound.getCenter());
      }

      google.maps.event.addListener(map, 'click', function() {
        infowindow.close();
      });
      function convertLatLong(location) {
        location = location.toString().replace('(', '').replace(')', '').replace(' ', '');
        location = location.split(',');
        var latLong = {lat: location[0], long: location[1]};
        return latLong;
      }
      function setMarkers(locations) {
        for (var i = 0; i < locations.length; i++) {
          var location = locations[i];
          var latLong = convertLatLong(location);
          var myLatLng = new google.maps.LatLng(latLong.lat, latLong.long);

          var image = {
            url: Drupal.settings.basePath + 'sites/all/themes/intobruges_theme/gfx/marker.svg',
            size: new google.maps.Size(21, 32),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(10, 32),
          }

          var marker = new google.maps.Marker({
            position: myLatLng,
            animation: google.maps.Animation.DROP,
            map: map,
            // icon: image,
            title: location[0]
        });

        // Open google maps at coordinates on marker click
        google.maps.event.addListener(marker, "click", function () {
          window.open("https://maps.google.com/maps?daddr=" + convertLatLong(location).lat + "," + convertLatLong(location).long + "&hl=nl");
        });
        gmarkers.push(marker);
      }
    }
    setMarkers(locations);
    }
})(jQuery);
