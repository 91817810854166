(function($) {
  Drupal.behaviors.hideUserNameBehavior = {
    attach: function(context, settings) {
      $(".user-register-form", context)
        .once("hideUserName")
        .each(function(index) {
          var $mailInput = $(this).find(".form-item-mail input");
          var $nameInput = $(this).find(".form-item-name input");

          $(this)
            .find(".form-item-name")
            .hide(0);

          function mailChange() {
            $nameInput.val($mailInput.val());
          }
          $mailInput.on("keyup", mailChange);
          $mailInput.on("change", mailChange);
        });
    }
  };

  Drupal.behaviors.productImage = {
    attach: function(context, settings) {
      $(".js-product-image", context)
        .once("productImage")
        .each(function(index) {
          var src = $(this)
            .find("img")
            .attr("src");
          $(this).css("background-image", 'url("' + src + '")');
        });
    }
  };

  Drupal.behaviors.productOrderTableIndexBehavior = {
    attach: function(context, settings) {
      $(".js-product-number", context)
        .once("productOrderTableIndex")
        .each(function(index) {
          var cleanIndex = index + 1 + "";
          if (cleanIndex.length < 2) cleanIndex = "0" + cleanIndex;
          $(this).text("N°" + cleanIndex);
        });
    }
  };

  function setStoreCookie() {
    var $productRows = $(".js-product-row");
    var productArr = [];

    $productRows.each(function() {
      var $row = $(this);
      $row.find(".js-product-quantity").each(function() {
        var $quantityField = $(this);
        if ($quantityField.val() * 1 > 0) {
          var productName = $row
            .find(".js-product-title")
            .text()
            .trim();
          var productSize = $quantityField.attr("data-size");
          var productPrice =
            $quantityField
              .attr("data-price")
              .split(",")
              .join(".") * 1;
          var productQuantity = $quantityField.val();

          var product = {
            name: productName,
            price: productPrice,
            size: productSize,
            quantity: productQuantity
          };
          productArr.push(product);
        }
      });
    });

    Cookies.set("shopOrder", JSON.stringify(productArr));
  }

  Drupal.behaviors.productQuantityBehavior = {
    attach: function(context, settings) {
      $(".js-product-row .js-product-quantity-wrapper", context)
        .once("productQuantity")
        .each(function() {
          var $moreButton = $(this).find(".js-product-more");
          var $minButton = $(this).find(".js-product-min");
          var $quantityField = $(this).find(".js-product-quantity");

          function getQuanity() {
            return $quantityField.val() * 1;
          }

          function validateQuantity() {
            if (getQuanity() < 0 || getQuanity() % 1 != 0) {
              setQuantity(0);
            }
          }

          function setQuantity(quantity) {
            $quantityField.val(quantity);
            validateQuantity();
            setStoreCookie();
          }

          $moreButton.click(function() {
            setQuantity(getQuanity() + 1);
          });

          $minButton.click(function() {
            setQuantity(getQuanity() - 1);
          });

          $quantityField.on("keyup", validateQuantity);
          $quantityField.on("change", validateQuantity);
        });
    }
  };

  Drupal.behaviors.fillOrderFormBehavior = {
    attach: function(context, settings) {
      $("#edit-submitted-bestellinggroup-bestelling", context)
        .once("fillOrderForm")
        .each(function() {
          var $textarea = $(this);
          var $webform = $(this).closest(".webform-client-form");
          var orders = Cookies.get("shopOrder");
          if (orders) orders = JSON.parse(orders);
          if (!orders || !orders.length) {
            console.log("no orders!");
            return $webform.hide(0);
          }

          $webform.show(0);
          $(".c-order-form-message__copy").hide(0);

          $textarea.prop("rows", orders.length);

          var orderString = "";
          var orderTotal = 0;
          var orderItemCount = 0;
          for (var i = 0; i < orders.length; i++) {
            if (i > 0) orderString += "\n";
            var order = orders[i];
            var orderLineItem =
              order.name +
              " (" +
              order.size +
              ")" +
              " - " +
              "€" +
              (order.price + "").split(".").join(",") +
              " x" +
              order.quantity;
            orderString += orderLineItem;
            orderTotal += order.price * order.quantity;
            orderItemCount += order.quantity;
          }

          orderString += "\n";
          orderString += "-----------------";
          orderString += "\n";
          orderString += "Totaal: €" + orderTotal;

          var textBoxSize = orders.length + 2;

          var discount = false;
          if (orderItemCount >= 10) {
            discount = true;
          }

          $(".webform-component--ambassador").hide(0);

          if ($(".webform-component--ambassador input").val()) {
            discount = true;
          }

          if (discount) {
            orderString += "\n";
            orderString += "-----------------";
            orderString += "\n";
            orderString += "Korting: " + "5%";
            orderString += "\n";
            orderString += "-----------------";
            orderString += "\n";
            var orderTotalAfterDiscount = (orderTotal * 0.95).toFixed(2);
            orderString += "Totaal na korting: €" + orderTotalAfterDiscount;
            textBoxSize += 4;
          }

          $textarea.attr("rows", textBoxSize);

          $textarea.val(orderString);
        });
    }
  };
})(jQuery);
