(function ($) {
  Drupal.behaviors.objectFitBehavior = {
    attach: function(context, settings){
      // if(Modernizr.objectfit) return false;
      $('.object-fit').once('objectFit', function(){
        var $container = $(this);
        console.log('fit');
        var $img = $(this).find('img');
        console.log($img.prop('src'));
        if(!$img.length) return false;
        $container.css('background-image', 'url('+$img.prop('src')+')');
        $img.remove();
      });
    }
  };

  Drupal.behaviors.videoSwipeboxBehavior = {
    attach: function(context, settings){
      $(context).find('.video__link').once('youtubeSwipbeox', function () {
        var $videoLink = $(this);
        $videoLink.click(function(e) {
          e.preventDefault();
          $.swipebox([{href: $(this).prop('href')}], {autoplayVideos: true, hideBarsDelay: 999999});
        });
      });
    }
  };

  Drupal.behaviors.socialFeedBehavior = {
    attach: function(context, settings){
      $('.fb-feed__inner', context).once('socialFeed', function(){
        // moment.locale('fr').format('LLL');
        // moment.lang('fr');
        // console.log(moment.locale());
        var $feedWrapper = $(this);
        // $feedWrapper.empty();
        $.get('/sites/all/themes/moonwood_theme/templates/socialfeed.html', init);

        function init(htmlTemplate) {
          $feedWrapper.socialfeed({
            template_html: htmlTemplate,
            // FACEBOOK
            facebook:{
                accounts: ['@Moonwoodgeuren'],
                limit: 1,
                // access_token: '1656945437884332|1a2c7971f4ed3d09c3a9330a33dfc0ef' // APP_ID|APP_SECRET
                access_token: '150849908413827|a20e87978f1ac491a0c4a721c961b68c' // APP_ID|APP_SECRET
            },
            show_media: true,
          });
        }
      });
    }
  };
  Drupal.behaviors.heroSliderBehavior = {
    attach: function(context, settings){
      $('.hero-slider', context).once('heroSlider', function(){
        var $container = $(this).find('.hero-slider__slide-wrp');
        var $prev = $(this).find('.hero-slider__nav-button--prev');
        var $next = $(this).find('.hero-slider__nav-button--next');
        $container.flickity({autoPlay: 10000, wrapAround: true, pageDots: false, percentPosition: true, setGallerySize: false, prevNextButtons:  false});
        $prev.click(function() { $container.flickity('previous'); });
        $next.click(function() { $container.flickity('next'); });
      });
    }
  };


  Drupal.behaviors.languageSwitchBehavior = {
    attach: function(context, settings){
      $('.language-switch', context).once('languageSwitch', function(){
        var $list = $(this).find('ul');
        $(this).find('li.active').prependTo($list);
      });
    }
  };

  Drupal.behaviors.svgPolyfullBehavior = {
    attach: function(context, settings){
      $('body', context).once('svgPolyfill', function(){
        svg4everybody();
      });
    }
  };


})(jQuery);
